import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Pleasure and Pain",
  "date": "2021-03-26T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Many teachers have said that one of the most difficult Buddhist words to translate is dukkha, and I would add that sukha is equally challenging. Sukha basically means feeling good, while dukkha means feeling bad, but the challenge is that we can and do mean this in many different ways. When referring to physical experience, these words usually mean pleasure and pain; in regard to mental experience, they come closer to gladness and sorrow; when applied to emotional experience, they can mean happiness and unhappiness or extend to something like well-being and anguish. Buddhist teachings take these terms even further to an existential level, where dukkha is the pervasive suffering of the first noble truth and sukha refers to its cessation in the ultimate welfare of awakening in this lifetime.`}</p>
    <p>{`I suggest that what we have here is a hierarchy of nestling sukhas and dukkhas, with each higher level being capable of holding and reconciling the levels below. We hear, for example, that the Buddha experienced wracking bodily pains in the final days of his life. But presumably these did not give rise to unhappiness or diminish his awakening in any way. Compare here the popular saying that while pain is inevitable, suffering is optional. In the simile of the two arrows (Samyutta Nikaya 36:6), we are told that weeping and wailing upon being struck with one arrow is like being struck again with a second arrow, the first being a physical feeling and the second a mental feeling. We also know from our own experience that stringing together moments of physical pleasure does not assure us mental happiness. In fact, it is often true that physical pleasure is a direct cause of mental unhappiness, as in the case of addiction.`}</p>
    <p>{`The physical pleasure and pain on the first rung of this sukha-dukkha ladder is hardwired into the human mind and body, and is both a natural and useful aspect of human experience. The goal of practice is not to pursue pleasure and avoid pain, but rather to experience both with full awareness, neither favoring one nor opposing the other. It is thus possible to experience mental pleasure or happiness while experiencing a certain amount of physical discomfort. A beautiful day hiking in the forest need not be entirely ruined by a blister on one’s foot, even though it makes itself known with every step. In the mental context of the second rung, the Pali prefixes su- and du- (meaning, more or less, “good” and “bad”) are retained, but are affixed to the word for mind (manas) to yield somanassa and domanassa, which might be translated as something like “glad-minded” and “sad-minded.”`}</p>
    <p>{`As we ascend this ladder of evolving meaning, we recognize that much of what Buddhist teachings address is a deeper emotional sukha and dukkha, which has more to do with the aggregate of volitional formations (sankhara) than with the aggregate of feeling. Here the sense of the words shifts slightly away from pleasure and pain in the direction of health and well-being on the one hand, and emotional dis-ease or distress on the other. In this context, we encounter words like “welfare” (hita) and “benefit” (attha), along with their opposite forms. The Buddha taught for the welfare and benefit of all beings, and those teachings instruct us on how to turn away from what is harmful to ourselves and others and cultivate what inclines to the welfare and benefit of both. We not only want to feel good and be happy, we also want to be well in a profound sense of the word. The phrases supporting metta practice come to mind in this regard: “May you be safe; may you be well; may you be free from harm.”`}</p>
    <p>{`Here again I think emotional sukha can encompass and absorb mental dukkha. I would like to think that one can feel the poignant sorrow of the loss of a loved one, for example, which would be an expression of mental pain, while at the same time feeling emotionally whole and well. When an arahant calls to mind or witnesses the dukkha of other beings, she feels mental pain as her heart trembles in the face of suffering, yet this is held in the embrace of a deeper wellness. The two terms are no longer polar opposites, but simply function at different levels of scale.`}</p>
    <p>{`Buddhist thought also links the notion of welfare to the ethical quality of mental and emotional states, behaviors, and traits. Ethical value is not something tacked onto things by human invention; it is built into the very fabric of consciousness. Here sukha and dukkha become entwined with the words kusala andakusala, which refer at the same time to what is healthy and unhealthy, and to what is skillful and unskillful. What this means is that true well-being can only come from expressions of generosity, kindness, and wisdom, while greed, hatred, and delusion will always result in harm. Furthermore, welfare is a skill that can be learned by anyone, and much of Buddhist practice is indeed the practicing of this skill.`}</p>
    <p>{`Ultimately, however, the Buddha was addressing an even higher, more far-reaching sense of sukha anddukkha, one that we might call existential. The noble truth of suffering means that even though there is gratification in sense pleasures, even though there may be moments of mental joy, and even though one might manage to be emotionally healthy, there is still a deep dissatisfaction inherent in the human condition. No matter how blessed our lives, we all face illness, aging, and death. And no matter how much goodness we might find in this world, the entire enterprise of life is caught up with the cruelties of beings devouring one another to survive.`}</p>
    <p>{`The solution to this deep dukkha is an even deeper sukha. Awakening is called the highest pleasure (paramam sukham), but the word is hardly adequate to express this paramount condition of ultimate well-being. It is not freedom from the conditions in which we find ourselves (no eternal bliss in this tradition), but it is freedom within them. Even though there is physical pain, we are capable of joy; even though there is mental sorrow, we are able to be well; and even though we are part of an impermanent, self-less flow of phenomena, we are nevertheless able to feel whole, complete, and deeply healthy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      